/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to true if you want to use the splash screen.
  useCustomCursor: true, // Change this to false if you want the good'ol cursor
  googleTrackingID: "UA-174238252-2",
};

//Home Page
const greeting = {
  title: "Hello 👋.",
  title2: "Prasad Shembekar",
  logo_name: "Portfolio.",
  nickname: "Mac / Smith",
  full_name: "Prasad Shembekar",
  subTitle: "Software Engineer, Tech Enthusiast 🔥 Always improving",
  resumeLink:
    "https://docs.google.com/document/d/11_gDNuacLk19hPOpGq8qtzgWWbro8_0hMLjko1d_5Mw/edit?usp=sharing",
  mail: "mailto:prasadmshembekar@gmail.com",
};

const socialMediaLinks = {
  /* Your Social Media Link */
  github: "https://github.com/prasad-shembekar",
  linkedin: "https://www.linkedin.com/in/prasadshembekar/",
  gmail: "prasadmshembekar@gmail.com",
  hackerrank: "https://www.hackerrank.com/prasadshembekar",
};

const skills = {
  data: [
    {
      title: "Full Stack Developer",
      fileName: "FullStackImg",
      skills: [
        "⚡ Develop highly interactive Front end / User Interfaces for your web and mobile applications",
        "⚡ Building responsive website front end using ReactJS",
        "⚡ Developing mobile applications using Flutter and Android",
        "⚡ Creating application backend in Node, Express & Flask",
        "⚡ Integration of third party services such as Firebase/ AWS / Digital Ocean",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "PHP",
          fontAwesomeClassname: "simple-icons:php",
          style: {
            color: "#7377AD",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#439743",
          },
        },
        // {
        //   skillName: "GraphQL",
        //   fontAwesomeClassname: "simple-icons:graphql",
        //   style: {
        //     color: "#DE33A6",
        //   },
        // },
        {
          skillName: "Android",
          fontAwesomeClassname: "simple-icons:android",
          style: {
            color: "#3DDC84",
          },
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "simple-icons:java",
          style: {
            color: "#f89820",
          },
        },
        // {
        //   skillName: "Flutter",
        //   fontAwesomeClassname: "simple-icons:flutter",
        //   style: {
        //     color: "#02569B",
        //   },
        // },
        // {
        //   skillName: "Dart",
        //   fontAwesomeClassname: "simple-icons:dart",
        //   style: {
        //     color: "#29B0EE",
        //   },
        // },
        // {
        //   skillName: "Visual Basic",
        //   fontAwesomeClassname: "simple-icons:dot-net",
        //   style: {
        //     color: "#029FCE",
        //   },
        // },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#4479A1",
          },
        },
        // {
        //   skillName: "jQuery",
        //   fontAwesomeClassname: "simple-icons:jquery",
        //   style: {
        //     color: "#0865A6",
        //   },
        // },
        {
          skillName: "Wordpress",
          fontAwesomeClassname: "simple-icons:wordpress",
          style: {
            color: "#207297",
          },
        },
        {
          skillName: "Apache",
          fontAwesomeClassname: "simple-icons:apache",
          style: {
            color: "#CA1A22",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "C",
          fontAwesomeClassname: "simple-icons:c",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "C++",
          fontAwesomeClassname: "simple-icons:cplusplus",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "simple-icons:python",
          style: {
            color: "#3776AB",
          },
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Experience hosting and managing websites",
        "⚡ Experience with Continuous Integration",
      ],
      softwareSkills: [
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Netlify",
          fontAwesomeClassname: "simple-icons:netlify",
          style: {
            color: "#38AFBB",
          },
        },
        {
          skillName: "Heroku",
          fontAwesomeClassname: "simple-icons:heroku",
          style: {
            color: "#6863A6",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "GitHub Actions",
          fontAwesomeClassname: "simple-icons:githubactions",
          style: {
            color: "#5b77ef",
          },
        },
      ],
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Government College Of Engineering, Amravati",
      subtitle: "Bachelor's Of Technology in Computer Science Engineering",
      logo_path: "logo-dark.png",
      alt_name: "GCOEA",
      duration: "2020 - 2023",
      descriptions: [
        "⚡ I'm graduated in June 2023 with CGPA 9.08",
        // "⚡ I have studied core subjects like Data Structures, DBMS, Networking, Security, etc.",
        // "⚡ I have also completed various online courses for Backend , Web , Mobile App Development, etc.",
        "⚡ Worked on different projects in university",
      ],
      website_link: "https://www.gcoea.ac.in/",
    },
    {
      title: "Government Polytechnic, Amravati",
      subtitle: "Diploma in Computer Science Engineering",
      logo_path: "ssgandhy.png",
      alt_name: "GPA",
      duration: "2017 - 2020",
      descriptions: [
        "⚡ I have diploma in computer science engineering with 93.69%",
        "⚡ I have also completed various online courses for Backend , Web , Mobile App Development, etc.",
      ],
      website_link: "https://www.gpamravati.ac.in/gpamravati_new/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "The Python Bible",
      subtitle: "Udemy",
      logo_path: "python.png",
      certificate_link:
        "https://drive.google.com/file/d/19MMr6t3-FrrlwEvidXTBDgQktmk41OtQ/view?usp=drive_link",
      alt_name: "Udemy Online",
      color_code: "#fffbf3",
      // color_code: "#87CEEB",
    },
    {
      title: "Java Training",
      subtitle: "Simplilearn",
      logo_path: "java.png",
      certificate_link:
        "https://drive.google.com/file/d/1wo8_TkWEsj0_i4PGw3gCWgkz2Khr76Yg/view?usp=drive_link",
      alt_name: "Simplilearn",
      color_code: "#fffbf3",
    },
    {
      title: "Complete MySQL Bootcamp",
      subtitle: "Udemy",
      logo_path: "mysql.png",
      certificate_link:
        "https://drive.google.com/file/d/1EwlY2O8gMxlhQogWVjbm9aZWFIKSbjqt/view?usp=drive_link",
      alt_name: "Udemy",
      // color_code: "#F6B808",
      color_code: "#fffbf3",
    },
    {
      title: "AI For Everyone",
      subtitle: "Coursera",
      logo_path: "ai.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/certificate/VTEJJBRDYKZ8",
      alt_name: "Deep learning ai",
      color_code: "#fffbf3",
    },
    {
      title: "ReactJs",
      subtitle: "Simplilearn",
      logo_path: "reactjs.png",
      certificate_link:
        "https://drive.google.com/file/d/1woHExM2ymRUiDxVQS2JEOtQYTK4Wcy61/view?usp=drive_link",
      alt_name: "Simplilearn",
      color_code: "#fffbf3",
    },
    {
      title: "Linux Programming",
      subtitle: "Udemy",
      logo_path: "linux.png",
      certificate_link:
        "https://drive.google.com/file/d/1vCwG9jMnEBzti401kgseoqT9Q5WYq9gX/view?usp=drive_link",
      alt_name: "Udemy",
      color_code: "#fffbf3",
    },
    {
      title: "Git Training",
      subtitle: "Udemy",
      logo_path: "git.png",
      certificate_link:
        "https://drive.google.com/file/d/1UCdkC8Y7DW2ENfkqcYzoL8ha6Falvc8L/view?usp=drive_link",
      alt_name: "Git",
      // color_code: "#f36c3d",
      color_code: "#fffbf3",
    },
    // color_code: "#8C151599",
    // color_code: "#7A7A7A",
    // color_code: "#0C9D5899",
    // color_code: "#C5E2EE",
    // color_code: "#ffc475",
    // color_code: "#g",
    // color_code: "#ffbfae",
    // color_code: "#fffbf3",
    // color_code: "#b190b0",
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I've completed one internship. I've mostly done projects on my own and I am actively looking for internships. I love organizing workshops to share my knowledge with others.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work Experience",
      experiences: [
        {
          title: "Work Experience Intern Forage",
          company: "J.P Morgan",
          company_url:
            "https://www.theforage.com/virtual-internships/prototype/R5iK7HMxJGBgaSbvk/JP-Morgan-Banking-Technology-Virtual-Program",
          logo_path: "valora.jpg",
          duration: "Feb 2023 - Mar 2023",
          location: "Remote",
          description: `Leveraged python's flexibility and extensive library ecosystem to develop scalable and adaptable stock market prediction systems enabling continuous improvement & adapt to changing market conditions with financial measures to extract meaningful insights and make informed decisions.`,
          // "I worked on the Dashboard project which helps users track their activities while using Walo Application. I also worked on Ocean Inventory Application and it's Admin panel Backend as well as on Ocean Inventory Admin Front-end using React and also worked on Walo Admin Backend.",
          color: "#0071C5",
        },
        // {
        //   title: "Full Stack Developer (Freelancer)",
        //   company: "Fake data",
        //   company_url: "http://hii.company/",
        //   logo_path: "hello.jpg",
        //   duration: "Sep 2020 - Oct 2020",
        //   location: "Remote",
        //   description: `hello`,
        //   // "Created Front end of Yearn Financial Mutual Funds website. also degined simple web application for better user experience, designed DB Schemas as well.",
        //   color: "#ee3c26",
        // },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        // {
        //   title: "Community Lead",
        //   company: "Bauddhik-Geeks",
        //   company_url: "https://bauddhikgeeks.tech/",
        //   logo_path: "bauddhikgeeks.png",
        //   duration: "Sep 2021 - Present",
        //   location: "Work From Home",
        //   description:
        //     "Help Tech-Geeks so they get exposure to the technical world and enlighten their skills. Hence, the programming club highlight all the skills of the individual members and help them develop and groom their skills in a meaningful way. Help community members to learn and grow.",
        //   color: "#FBBD18",
        // },
        {
          title: "GDG Student Volunteer",
          company: "Google Developer Groups",
          company_url: "https://gdg.community.dev/",
          logo_path: "gdg.png",
          duration: "Jan 2020 - Present",
          location: "Work From Home",
          description:
            "Google Developer Group Amravati Student Volunteer and Member.",
          color: "#D83B01",
        },
        // {
        //   title: "Postman Student Expert",
        //   company: "Postman",
        //   company_url: "https://www.postman.com/",
        //   logo_path: "postman.png",
        //   duration: "Feb 2020 - Present",
        //   location: "Work From Home",
        //   description:
        //     "Google Developer Group Surat Student Volunteer and Member.",
        //   color: "#D83B01",
        // },
        {
          title: "GitHub Student Developer",
          company: "GitHub",
          company_url: "https://github.com/",
          logo_path: "github.png",
          duration: "Jul 2020 - Present",
          location: "Work from Home",
          description:
            "Contribute to Open Source Community and Open Source Project.",
          color: "#040f26",
        },
        // {
        //   title: "Google Local Guide",
        //   company: "Google Map",
        //   company_url: "https://maps.google.com/localguides/",
        //   logo_path: "localguide.png",
        //   duration: "Sep 2018 - Present",
        //   location: "Work From Home",
        //   description:
        //     "Day-to-day responsibilities of helping local businesses to spread their business to the world. Helping users by writing reviews about different locations and spaces such as shops, malls, etc.",
        //   color: "#D83B01",
        // },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects make use of a vast variety of latest technology tools. My best experience is to create NodeJS Backend Projects, Python Scripts, and React Project. Below are some of my projects. Note that not all of the mentioned projects are on GitHub yet.",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "prasad.jpg",
    description:
      "You can contact me at the places mentioned below. I will try to get back to you as fast as I can. ",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I don't blog frequently but when I do something awesome, I do try to document it so it can be helpful to others. I write on Twitter.",
    link: "https://hashnode.com/@prasadshembekar05",
    avatar_image_path: "blogs_image.svg",
  },
};

const projects = {
  data: [
    {
      id: "0",
      name: "FruitSack : AI-Driven Fruit Pricing",
      url: "https://github.com/prasad-shembekar/FruitSack",
      description:
        "Webapp designed for connecting with fruit vendors and customers to help users buy good quality fruits with reduced cost of rotten and worst quality fruit.",
      languages: [
        {
          name: "Python 3.11",
          iconifyClass: "logos-python",
        },
        {
          name: "Java 17",
          iconifyClass: "logos-java",
        },
        {
          name: "Spring Boot",
          iconifyClass: "simple-icons:springboot",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "MySQL",
          iconifyClass: "logos-mysql",
        },
      ],
      links: [
        {
          name: "Github",
          url: "https://github.com/prasad-shembekar/FruitSack",
          iconifyClass: "ri:github-fill",
        },
        {
          name: "Live Demo",
          url: "https://github.com/prasad-shembekar/FruitSack",
          iconifyClass: "mdi:web",
        },
      ],
    },
    {
      id: "1",
      name: "CryptoSage: An Crypto Tracker",
      url: "https://github.com/prasad-shembekar/CryptoSage",
      description:
        "It is a comprehensive and user-friendly tool that empowers cryptocurrency investors to stay informed about market changes to real-time data aiding timely decision-making",
      languages: [
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "ReactJs",
          iconifyClass: "vscode-icons:file-type-reactjs",
        },
        {
          name: "MaterialUI",
          iconifyClass: "devicon:materialui",
        },
        {
          name: "Coinbase API",
          iconifyClass: "tabler:brand-coinbase",
        },
        {
          name: "Postman",
          iconifyClass: "devicon:postman",
        },
      ],
      links: [
        {
          name: "Github",
          url: "https://github.com/prasad-shembekar/CryptoSage",
          iconifyClass: "ri:github-fill",
        },
        {
          name: "Live Demo",
          url: "https://github.com/prasad-shembekar/CryptoSage",
          iconifyClass: "mdi:web",
        },
      ],
    },
    {
      id: "2",
      name: "IdeaVault: Notes taking Web App",
      url: "https://github.com/prasad-shembekar/IdeaVault",
      description:
        "IdeaVault is a reliable and efficient note-taking app that empowers users to organize their ideas, tasks effectively.Whether use it for personal use, education purpose.",
      languages: [
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "ReactJs",
          iconifyClass: "vscode-icons:file-type-reactjs",
        },
        {
          name: "MongoDB",
          iconifyClass: "vscode-icons:file-type-mongo",
        },
        {
          name: "ExpressJs",
          iconifyClass: "skill-icons:expressjs-dark",
        },
        {
          name: "NodeJs",
          iconifyClass: "logos-nodejs",
        },
      ],
      links: [
        {
          name: "Github",
          url: "https://github.com/prasad-shembekar/IdeaVault",
          iconifyClass: "ri:github-fill",
        },
        {
          name: "Live Demo",
          url: "https://github.com/prasad-shembekar/IdeaVault",
          iconifyClass: "mdi:web",
        },
      ],
    },
    {
      id: "3",
      name: "SmartCCTVShield",
      url: "https://github.com/prasad-shembekar/SmartCCTVShield",
      description:
        "It is a comprehensive and user-friendly window gui that helps to keep track of the surrounding within the workstation.",
      languages: [
        {
          name: "Python 3.11",
          iconifyClass: "logos-python",
        },
        {
          name: "Machine Learning",
          iconifyClass: "fluent-mdl2:machine-learning",
        },
        {
          name: "ScikitLearn",
          iconifyClass: "devicon:scikitlearn",
        },
        {
          name: "Keras",
          iconifyClass: "devicon:keras-wordmark",
        },
      ],
      links: [
        {
          name: "Github",
          url: "https://github.com/prasad-shembekar/SmartCCTVShield",
          iconifyClass: "ri:github-fill",
        },
        {
          name: "Live Demo",
          url: "https://github.com/prasad-shembekar/SmartCCTVShield",
          iconifyClass: "mdi:web",
        },
      ],
    },
    {
      id: "4",
      name: "Face Mask Detection",
      url: "https://github.com/prasad-shembekar/mask-detection",
      description:
        "An app that helps to detect and identify the mask in crowd of many people.",
      languages: [
        {
          name: "Python 3.11",
          iconifyClass: "logos-python",
        },
        {
          name: "Machine Learning",
          iconifyClass: "fluent-mdl2:machine-learning",
        },
        {
          name: "Keras",
          iconifyClass: "devicon:keras-wordmark",
        },
        {
          name: "ScikitLearn",
          iconifyClass: "devicon:scikitlearn",
        },
      ],
      links: [
        {
          name: "Github",
          url: "https://github.com/prasad-shembekar/mask-detection",
          iconifyClass: "ri:github-fill",
        },
        {
          name: "Live Demo",
          url: "https://github.com/prasad-shembekar/mask-detection",
          iconifyClass: "mdi:web",
        },
      ],
    },
  ],
};
// {
//   id: "5",
//   name: "Portfolio Website",
//   url: "https://github.com/harikanani/Fileblok",
//   description:
//     "Keep your projects moving with seamless transfer experice. FileBlok is a file transfer app that allows you to transfer files from one device to another without any hassle. We built this app using Flutter, Node.js and StoryBlok.",
//   languages: [
//     {
//       name: "Flutter",
//       iconifyClass: "logos-flutter",
//     },
//     {
//       name: "NodeJs",
//       iconifyClass: "logos-nodejs",
//     },
//     {
//       name: "Storyblok",
//       iconifyClass: "logos-storyblok-icon",
//     },
//   ],
//   links: [
//     {
//       name: "Github",
//       url: "https://github.com/harikanani/Fileblok",
//       iconifyClass: "ri:github-fill",
//     },
//     {
//       name: "Devpost",
//       url: "https://devpost.com/software/fileblok",
//       iconifyClass: "fluent:window-dev-edit-20-filled",
//     },
//   ],
// },
// {
//   id: "6",
//   name: "BAUDDIK-GEEKS PORTFOLIO",
//   url: "https://github.com/Bauddhik-Geeks/Bauddhik-Geeks.github.io",
//   description:
//     "Awesome Community Portfolio website for Bauddhik-Geeks community",
//   languages: [
//     {
//       name: "HTML5",
//       iconifyClass: "vscode-icons:file-type-html",
//     },
//     {
//       name: "CSS3",
//       iconifyClass: "vscode-icons:file-type-css",
//     },
//     {
//       name: "JavaScript",
//       iconifyClass: "logos-javascript",
//     },
//   ],
//   links: [
//     {
//       name: "Github",
//       url: "https://github.com/Bauddhik-Geeks/Bauddhik-Geeks.github.io",
//       iconifyClass: "ri:github-fill",
//     },
//     {
//       name: "Live Demo",
//       url: "https://bauddhikgeeks.co/",
//       iconifyClass: "mdi:web",
//     },
//   ],
// },
// {
//   id: "7",
//   name: "Votechain",
//   url: "https://github.com/abhigoyani/votechain",
//   description:
//     "Awesome Community Portfolio website for Bauddhik-Geeks community",
//   languages: [
//     {
//       name: "Flask",
//       iconifyClass: "logos-flask",
//     },
//     {
//       name: "Python",
//       iconifyClass: "logos-python",
//     },
//     {
//       name: "HTML5",
//       iconifyClass: "logos-html-5",
//     },
//     {
//       name: "CSS3",
//       iconifyClass: "logos-css-3",
//     },
//     {
//       name: "MongoDB",
//       iconifyClass: "logos-mongodb-icon",
//     },
//   ],
//   links: [
//     {
//       name: "Github",
//       url: "https://github.com/abhigoyani/votechain",
//       iconifyClass: "ri:github-fill",
//     },
//   ],
// },
// {
//   id: "8",
//   name: "Swag Store",
//   url: "https://github.com/harikanani/e-commerce-frontend",
//   description:
//     "An E-commerce website built using MERN[Mongo, Express, React, Node] stack and we have also added stripe payment gateway integration [in test mode].",
//   languages: [
//     {
//       name: "ReactJS",
//       iconifyClass: "logos-react",
//     },
//     {
//       name: "NodeJS",
//       iconifyClass: "logos-nodejs",
//     },
//     {
//       name: "MedusaJS",
//       iconifyClass: "logos-medusa-icon",
//     },
//   ],
//   links: [
//     {
//       name: "Github",
//       url: "https://github.com/harikanani/e-commerce-frontend",
//       iconifyClass: "ri:github-fill",
//     },
//   ],
// },
// {
//   id: "9",
//   name: "node-blockchain",
//   url: "https://github.com/harikanani/node-blockchain",
//   description:
//     "A simple blockchain and cryptocurrency wallet implemented in Node.js and TypeScript (for learning purposes).",
//   languages: [
//     {
//       name: "NodeJS",
//       iconifyClass: "logos-nodejs",
//     },
//     {
//       name: "TypeScript",
//       iconifyClass: "skill-icons:typescript",
//     },
//   ],
//   links: [
//     {
//       name: "Github",
//       url: "https://github.com/harikanani/node-blockchain",
//       iconifyClass: "ri:github-fill",
//     },
//   ],
// },
// {
//   id: "10",
//   name: "Know Me Well",
//   url: "https://github.com/harikanani/personal-portfolio",
//   description:
//     "A simple command line interface based quiz app to know more about me :).",
//   languages: [
//     {
//       name: "JavaScript",
//       iconifyClass: "logos-javascript",
//     },
//     {
//       name: "NodeJS",
//       iconifyClass: "logos-nodejs",
//     },
//     {
//       name: "Repl.it",
//       iconifyClass: "logos-replit-icon",
//     },
//   ],
//   links: [
//     {
//       name: "Github",
//       url: "https://github.com/harikanani/personal-portfolio",
//       iconifyClass: "ri:github-fill",
//     },
//   ],
// },
// {
//   id: "11",
//   name: "Bloggify",
//   url: "https://github.com/harikanani/Bloggify",
//   description: "A Simple REST API for Blog Application.",
//   languages: [
//     {
//       name: "NodeJS",
//       iconifyClass: "logos-nodejs",
//     },
//   ],
//   links: [
//     {
//       name: "Github",
//       url: "https://github.com/harikanani/Bloggify",
//       iconifyClass: "ri:github-fill",
//     },
//   ],
// },
// {
//   id: "12",
//   name: "Stargazzers",
//   url: "https://github.com/harikanani/stargazers",
//   description: "An Unofficial API for GitHub Repo Stars Count",
//   languages: [
//     {
//       name: "NodeJs",
//       iconifyClass: "logos-nodejs",
//     },
//   ],
//   links: [
//     {
//       name: "Github",
//       url: "https://github.com/harikanani/stargazers",
//       iconifyClass: "ri:github-fill",
//     },
//   ],
// },
// {
//   id: "13",
//   name: "GitHub Theme Portfolio",
//   url: "https://github.com/harikanani/harikanani.github.io",
//   description:
//     "A Personal Portfolio Website that showcases my work and experience. which is hosted on Github Pages.",
//   languages: [
//     {
//       name: "HTML5",
//       iconifyClass: "vscode-icons:file-type-html",
//     },
//     {
//       name: "CSS3",
//       iconifyClass: "vscode-icons:file-type-css",
//     },
//     {
//       name: "JavaScript",
//       iconifyClass: "logos-javascript",
//     },
//     {
//       name: "SCSS",
//       iconifyClass: "vscode-icons:file-type-scss2",
//     },
//   ],
//   links: [
//     {
//       name: "Github",
//       url: "https://github.com/harikanani/harikanani.github.io",
//       iconifyClass: "ri:github-fill",
//     },
//     {
//       name: "Live Demo",
//       url: "https://harikanani.github.io/",
//       iconifyClass: "mdi:web",
//     },
//   ],
// },
// {
//   id: "6",
//   name: "Automate Attendace",
//   url: "https://github.com/harikanani/Node_Python",
//   description: "Automation of Online Attendance using node js and python",
//   languages: [
//     {
//       name: "JavaScript",
//       iconifyClass: "logos-javascript",
//     },
//     {
//       name: "Python",
//       iconifyClass: "logos-python",
//     },
//   ],
//   links: [
//     {
//       name: "Github",
//       url: "",
//       iconifyClass: "ri:github-fill",
//     },
//     {
//       name: "Devfolio",
//       url: "",
//       iconifyClass: "ri:github-fill",
//     },
//   ],
// },
// {
//   id: "7",
//   name: "Automate Discord Bot",
//   url: "https://github.com/harikanani/AutomateDiscordBot",
//   description:
//     "A Discord Bot to send Automatic messages to serer channel in regular time difference.",
//   languages: [
//     {
//       name: "Python",
//       iconifyClass: "logos-python",
//     },
//     {
//       name: "Python Selenium",
//       iconifyClass: "logos-selenium",
//     },
//     {
//       name: "Chromium Browser",
//       iconifyClass: "openmoji-chromium",
//     },
//   ],
//   links: [
//     {
//       name: "Github",
//       url: "",
//       iconifyClass: "ri:github-fill",
//     },
//     {
//       name: "Devfolio",
//       url: "",
//       iconifyClass: "ri:github-fill",
//     },
//   ],
// },
// {
//   id: "8",
//   name: "Flask Blog",
//   url: "https://github.com/harikanani/flask_blog",
//   description: "A Simple Blog Web Application made using Flask Framework",
//   languages: [
//     {
//       name: "Python",
//       iconifyClass: "logos-python",
//     },
//     {
//       name: "Flask",
//       iconifyClass: "cib-flask",
//     },
//     {
//       name: "HTML5",
//       iconifyClass: "vscode-icons:file-type-html",
//     },
//     {
//       name: "CSS3",
//       iconifyClass: "vscode-icons:file-type-css",
//     },
//   ],
//   links: [
//     {
//       name: "Github",
//       url: "",
//       iconifyClass: "ri:github-fill",
//     },
//     {
//       name: "Devfolio",
//       url: "",
//       iconifyClass: "ri:github-fill",
//     },
//   ],
// },
// {
//   id: "9",
//   name: "Netflix top series",
//   url: "https://github.com/harikanani/netflix-top-series",
//   description: "List of Top Netflix Series which is deployed to vercel.",
//   languages: [
//     {
//       name: "JavaScript",
//       iconifyClass: "logos-javascript",
//     },
//     {
//       name: "ReactJS",
//       iconifyClass: "logos-react",
//     },
//     {
//       name: "HTML5",
//       iconifyClass: "logos-html-5",
//     },
//     {
//       name: "CSS3",
//       iconifyClass: "logos-css-3",
//     },
//   ],
//   links: [
//     {
//       name: "Github",
//       url: "",
//       iconifyClass: "ri:github-fill",
//     },
//     {
//       name: "Devfolio",
//       url: "",
//       iconifyClass: "ri:github-fill",
//     },
//   ],
// },
// {
//   id: "10",
//   name: "COVID-19 Tracker",
//   url: "https://github.com/harikanani/Covid19TrackerReact",
//   description:
//     "Simple Covid-19 Tracker made using React and deployed to Vercel.",
//   languages: [
//     {
//       name: "JavaScript",
//       iconifyClass: "logos-javascript",
//     },
//     {
//       name: "ReactJS",
//       iconifyClass: "logos-react",
//     },
//     {
//       name: "HTML5",
//       iconifyClass: "logos-html-5",
//     },
//     {
//       name: "CSS3",
//       iconifyClass: "logos-css-3",
//     },
//   ],
//   links: [
//     {
//       name: "Github",
//       url: "https://github.com/harikanani/Covid19TrackerReact",
//       iconifyClass: "ri:github-fill",
//     },
//   ],
// },
// {
//   id: "11",
//   name: "Food Order Static Website",
//   url: "https://github.com/harikanani/food-order-website",
//   description:
//     "A simple static website related to food restaurants service. this is reasponsive as well.",
//   languages: [
//     {
//       name: "HTML5",
//       iconifyClass: "logos-html-5",
//     },
//     {
//       name: "CSS3",
//       iconifyClass: "logos-css-3",
//     },
//     {
//       name: "JavaScript",
//       iconifyClass: "logos-javascript",
//     },
//     {
//       name: "PHP",
//       iconifyClass: "logos-php",
//     },
//   ],
//   links: [
//     {
//       name: "Github",
//       url: "",
//       iconifyClass: "ri:github-fill",
//     },
//     {
//       name: "Devfolio",
//       url: "",
//       iconifyClass: "ri:github-fill",
//     },
//   ],
// },
// {
//   id: "12",
//   name: "NFT Launchpad",
//   url: "https://deliquescent-cents.000webhostapp.com/",
//   description: "NFT Launchpad crypto site portfolio",
//   languages: [
//     {
//       name: "HTML5",
//       iconifyClass: "logos-html-5",
//     },
//     {
//       name: "CSS3",
//       iconifyClass: "logos-css-3",
//     },
//     {
//       name: "JavaScript",
//       iconifyClass: "logos-javascript",
//     },
//     {
//       name: "ReactJS",
//       iconifyClass: "logos-react",
//     },
//   ],
//   links: [
//     {
//       name: "Github",
//       url: "https://github.com/harikanani/nft-launchpad",
//       iconifyClass: "ri:github-fill",
//     },
//   ],
// },

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
  projects,
};
